@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: local('Inter Extra Light'), local('Inter-Light'), url('#{$font-path}/Inter-ExtraLight.woff2') format('woff2'), url('#{$font-path}/Inter-ExtraLight.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  src: local('Inter Extra Light Italic'), local('Inter-ExtraLightItalic'), url('#{$font-path}/Inter-ExtraLightItalic.woff2') format('woff2'), url('#{$font-path}/Inter-ExtraLightItalic.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: local('Inter Light'), local('Inter-Light'), url('#{$font-path}/Inter-Light.woff2') format('woff2'), url('#{$font-path}/Inter-Light.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  src: local('Inter Light Italic'), local('Inter-LightItalic'), url('#{$font-path}/Inter-LightItalic.woff2') format('woff2'), url('#{$font-path}/Inter-LightItalic.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter Regular'), local('Inter-Regular'), url('#{$font-path}/Inter-Regular.woff2') format('woff2'), url('#{$font-path}/Inter-Regular.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  src: local('Inter Regular Italic'), local('Inter-RegularItalic'), url('#{$font-path}/Inter-RegularItalic.woff2') format('woff2'), url('#{$font-path}/Inter-RegularItalic.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local('Inter Medium'), local('Inter-Medium'), url('#{$font-path}/Inter-Medium.woff2') format('woff2'), url('#{$font-path}/Inter-Medium.woff') format('woff');
  font-display: swap; }

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  src: local('Inter Medium Italic'), local('Inter-MediumItalic'), url('#{$font-path}/Inter-MediumItalic.woff2') format('woff2'), url('#{$font-path}/Inter-MediumItalic.woff') format('woff');
  font-display: swap; }
